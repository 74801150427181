<template>
  <div class="message-list">
    <div class="message-th">
      <div class="message-time">Time</div>
      <div class="message-title">Title</div>
      <div class="message-content">Content</div>
      <div class="message-status">Status</div>
    </div>
    <el-scrollbar height="720px">
      <ul v-if="messages.length !== 0">
        <li v-for="(message, index) in messages" :key="index" @click="showMessage(message)">
          <div class="message-time">{{ dateFormat(message.timestamp) }}</div>
          <div class="message-title">{{ message.title }}</div>
          <div class="message-content">{{ message.content }}</div>
          <div class="message-status">{{ message.status === 0 ? 'Unread' : 'Already Read' }}</div>
        </li>
      </ul>
      <el-empty v-else description="No Data"/>
    </el-scrollbar>

    <el-dialog
      :title="selectedMessage.title"
      v-model="dialogVisible"
      width="50%"
    >
      <p>{{ selectedMessage.content }}</p>
      <template #footer>
        <el-button @click="dialogVisible = false">Close</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { post } from "@/api";
import { formatDateTime } from "@/utils/dateFormatter";
import { useRoute } from "vue-router";

export default {
  name: "UserMessage",
  setup() {
    const messages = ref([]);
    const dialogVisible = ref(false);
    const selectedMessage = ref({ timestamp: "", content: "" });
    const route = useRoute();

    const loadMessages = () => {
      post("message/get-user-messages", { userId: route.query.uid }).then((res) => {
        // console.log('API Response:', res); // Log the response to check its structure
        if (res && Array.isArray(res)) {
          messages.value = res.map(msg => ({
            ...msg,
            timestamp: msg.timestamp,
            title: msg.title,
            content: msg.content,
            status: msg.isRead // Ensure status is included
          }));
          console.log('Mapped Messages:', messages.value); // Log the mapped messages
        } else {
          console.error('Unexpected API response structure:', res); // Log unexpected response
        }
      }).catch((error) => {
        console.error('Error loading messages:', error); // Log any errors
      });
    };

    const showMessage = (message) => {
      selectedMessage.value = message;
      dialogVisible.value = true;
      post("message/mark-as-read", {userId: route.query.uid, messageId: message.messageId }).then((res) => {
        if (res) {
          // Update the status of the message to 'Read'
          message.status = 1;
        } else {
          console.error('Mark as read failed');
        }
      }).catch((error) => {
        console.error('Error marking message as read:', error);
      });
    };

    const dateFormat = (date) => {
      return formatDateTime(new Date(date), 'yyyy-MM-dd HH:mm:ss');
    };

    onMounted(() => {
      loadMessages();
    });

    return {
      messages,
      dialogVisible,
      selectedMessage,
      showMessage,
      dateFormat,
    };
  },
};
</script>


<style scoped>
.message-list {
  display: block;
  padding: 20px 25px;
}
.message-th {
  display: flex;
  font-family: "Poppins-SemiBold";
  font-size: 18px;
  color: #111880;
  padding: 20px 0;
  border-bottom: 1px solid #DADADA;
}
.message-time {
  width: 30%;
  text-align: left;
  padding: 0 15px;
}
.message-title {
  width: 30%;
  text-align: left;
  padding: 0 15px;
}
.message-content {
  width: 40%;
  text-align: left;
  padding: 0 15px;
}
.message-list ul {
  display: block;
  height: 475px;
  -webkit-overflow-scrolling: touch;
}
.message-list ul::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;
  position: absolute;
}
.message-list ul::-webkit-scrollbar-thumb {
  background: #D9D9D9;
  border-radius: 5px;
}
.message-list li {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-family: "Poppins-Medium";
  line-height: 1.5;
  padding: 20px 0;
  border-bottom: 1px solid #DADADA;
  cursor: pointer;
}
.message-list li:hover {
  box-shadow: 0px 2px 15px 0px rgba(59, 93, 245, 0.5);
}
</style>
